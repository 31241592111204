import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import { graphql } from "gatsby"
import PropTypes from "prop-types"

class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost
    const fluid = (post.featured_media) ? post.featured_media.localFile.childImageSharp.fluid : null

    return (
      <Layout>
        <SEO
          title={post.title}
          description={post.excerpt}
        />

        <div className="entry-template">
          <h1>{post.title}</h1>
          <p className="post-date">{post.date}</p>

          {fluid &&
            <div>
              <Img className="feat_img" fluid={fluid} alt={post.title} />
            </div>
          }

          <div
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMM DD, YYYY")
      featured_media {
        localFile {
          childImageSharp {
            fluid (maxWidth: 700) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`